import React from 'react'
import { getHotelOfferDisplayedValue } from 'lib/offer/getOfferDisplayPricing'
import { isTourV2OfferType } from 'lib/offer/offerTypes'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import { useOfferPrice } from 'hooks/Offers/useOfferPrice'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import PriceRowLoyaltyPrice from 'components/Luxkit/PricePoints/Loyalty/PriceRowLoyaltyPrice'
import LuxPlusPriceGuard from 'luxPlus/components/LuxPlusPriceGuard'

interface MappedProps {
  canViewLuxPlusBenefits: boolean
}

interface Props extends MappedProps {
  offerType: App.OfferType
  duration?: string
  price: number
  memberPrice: number
  value?: number
  hotelNights?: number
  shouldDisplayValue?: boolean
}

function HotelPriceContent({
  offerType,
  duration,
  memberPrice,
  price,
  value,
  hotelNights,
  shouldDisplayValue = false,
  canViewLuxPlusBenefits,
}: Props) {
  let hotelOfferDisplayValue: ReturnType<typeof getHotelOfferDisplayedValue> | undefined
  const displayPrice = canViewLuxPlusBenefits && memberPrice > 0 ? memberPrice : price
  const isTourV2 = isTourV2OfferType(offerType)
  if (!isTourV2) {
    hotelOfferDisplayValue = getHotelOfferDisplayedValue(false, displayPrice, value ?? displayPrice, shouldDisplayValue)
  }

  const durationText = duration ? `${duration} from` : 'From'

  const {
    total,
    saleUnit,
  } = useOfferPrice({
    value: undefined,
    saleUnit: 'total',
    duration: hotelNights,
    total: displayPrice,
  })

  return <div>
    <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
    <LuxPlusPriceGuard
      showMemberPrice={canViewLuxPlusBenefits && memberPrice > 0}
      fallbackPrice={<PriceRowPrice
        size="S"
        price={Math.ceil(total)}
        saleUnit={saleUnit}
        /** @todo Probably YmalPricingProperties needs to include refs to taxes and fees! */
        discountPercentage={!isTourV2 && hotelOfferDisplayValue?.shouldShowDiscountPercent ? hotelOfferDisplayValue.highestDiscountPercent : undefined}
      />}
    >
      <PriceRowLoyaltyPrice
        size="S"
        memberPrice={Math.ceil(total)}
        saleUnit={saleUnit}
        discountPercentage={!isTourV2 && hotelOfferDisplayValue?.shouldShowDiscountPercent ? hotelOfferDisplayValue.highestDiscountPercent : undefined}
      />
    </LuxPlusPriceGuard>
  </div>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(HotelPriceContent)
