import React, { useCallback, useContext, useMemo, useEffect } from 'react'

import styled from 'styled-components'
import { rem } from 'polished'

import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_TOUR } from 'constants/offer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BundledHotelPriceContent from './BundledHotelPriceContent'
import HotelPriceContent from './HotelPriceContent'
import { DistanceUnit } from 'lib/geo/distanceUnits'
import { getDistanceLabel } from 'lib/customer/recommendationUtils'
import Image from 'components/Common/Image'
import Group from 'components/utils/Group'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import Clickable from 'components/Common/Clickable/Clickable'
import { useInView } from 'react-intersection-observer'
import AspectRatio from 'components/utils/AspectRatio'
import { KEYBOARD_MODE_CSS_VAR } from 'contexts/InputModeContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { OfferBestPriceData } from 'selectors/offerSelectors'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Root = styled(Clickable)`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  overflow: hidden;
  transition: outline 0.2s, box-shadow 0.2s;

  ${mediaQueryUp.tablet} {
    padding: ${rem(8)};
    width: unset;
    min-width: ${rem(320)};

    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }
  }

  &.tile-border {
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    border-radius: ${props => props.theme.borderRadius.XS};
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

const validOfferTypes: Array<App.OfferType> = [
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  'direct_tour',
  'partner_tour',
  'connection_tour',
  OFFER_TYPE_BED_BANK,
]

export function shouldDisplay(offerType: App.OfferType) {
  return validOfferTypes.includes(offerType)
}

interface PricingProps {
  duration: string
  hotelNights?: number
  price: number
  memberPrice: number
  saleUnit: string
}

interface FlightProps {
  bundledWithFlightsOnly?: boolean
  flightOrigin?: string
  flightPrices?: { [airportCode: string]: number }
}

interface Props {
  image: App.Image
  title: string
  location: string
  offerType: App.OfferType
  position: number
  testId: string
  url: string
  distance?: number,
  distanceFrom?: string,
  distanceUnit?: DistanceUnit,
  flights?: FlightProps
  shouldDisplayValue?: boolean
  value?: number
  reviewData?: {
    rating: number,
    reviewsTotal: number,
    reviewsSource: string,
  }
  pricing?: PricingProps,
  offer?: App.AnyOffer,
  alternativePrice?: OfferBestPriceData
}

function YouMayAlsoLikeTile({
  distance,
  distanceFrom,
  distanceUnit,
  flights,
  image,
  title,
  offerType,
  position,
  pricing,
  shouldDisplayValue,
  url,
  value,
  reviewData,
  testId,
  offer,
  alternativePrice,
}: Props) {
  const price = alternativePrice?.bestPrice ?? pricing?.price
  const duration = alternativePrice?.bestPricePackage?.durationLabel ?? pricing?.duration
  const memberPrice = alternativePrice?.bestMemberPrice ?? pricing?.memberPrice
  const hotelNights = alternativePrice?.duration ?? pricing?.hotelNights

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    skip: !offer,
    threshold: OFFER_TRACKING_IN_VIEW_THRESHOLD,
  })

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  useEffect(() => {
    if (inView && !!offer) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
      })
    }
  }, [dispatchOfferListEvent, inView, offer, position])

  const fireProductClick = useCallback(() => {
    if (offer) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position,
      })
    }
  }, [dispatchOfferListEvent, offer, position])

  const handleClick = useCallback(() => {
    fireProductClick()
  }, [fireProductClick])

  const showHotelPrice = pricing && !flights?.bundledWithFlightsOnly
  const showBundledHotelPrice = pricing && flights?.bundledWithFlightsOnly

  const distanceText = useMemo(() => {
    if (!distance || !distanceFrom || !distanceUnit) {
      return null
    }
    return getDistanceLabel(distance, distanceFrom, distanceUnit)
  }, [distance, distanceUnit, distanceFrom])

  if (!shouldDisplay(offerType)) {
    return null
  }

  return (
    <Root
      to={url}
      onClick={handleClick}
      target="_blank"
      data-testid={testId}
      ref={inViewRef}
    >
      <Group direction="horizontal" gap={12}>
        <AspectRatio ratio="5:6" width="100px">
          <Image
            id={image.id}
            alt={image.title}
            width={100}
            height={120}
            dpr={2}
            fit="center"
          />
        </AspectRatio>
        <Group direction="vertical" fullWidth gap={4}>
          <Group direction="vertical" fullHeight gap={4}>
            <Group direction="vertical">
              {distanceText && <Caption variant="large" colour="neutral-three" lineClamp={1} wrap="letter-wrap">{distanceText}</Caption>}
              <BodyText variant="medium" weight="bold" lineClamp={1} wrap="letter-wrap">{title}</BodyText>
            </Group>
            {!!reviewData?.rating &&
              <NumberRating
                variant="xs"
                rating={reviewData.rating}
                hideLink
              />
            }
          </Group>
          {showHotelPrice &&
            <HotelPriceContent
              offerType={offerType}
              price={price!}
              memberPrice={memberPrice!}
              duration={duration}
              value={value}
              hotelNights={hotelNights}
              shouldDisplayValue={shouldDisplayValue}
            />
          }
          {showBundledHotelPrice &&
            <BundledHotelPriceContent
              bundledWithFlightsOnly={!!flights?.bundledWithFlightsOnly}
              flightPrices={flights?.flightPrices ?? {}}
              price={price!}
              memberPrice={memberPrice!}
              duration={duration!}
              hotelNights={hotelNights}
              value={value}
              offer={offer}
            />
          }
        </Group>
      </Group>
    </Root>
  )
}

export default React.memo(YouMayAlsoLikeTile)
